'use client';

import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import type { ReactPortal } from 'react';
import { TextInput, SearchIcon } from '@/shared/ui';
import { useGameSearch } from '@/widgets/casino/lobby-navigation/lib/use-game-search';
import { useScopedI18n } from '@/shared/locales/client';
import { useGamesByCategory } from '@/entities/game';
import { useCategories } from '../../lobby-navigation/lib/use-categories';
import { Game } from '@/entities/game/types';
import { GridView } from './grid-view';
import { SearchResults } from './search-results';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  activeCategory?: string;
};

export const GameSearchModal: React.FC<Props> = ({ isOpen, onClose, activeCategory }) => {
  const t = useScopedI18n('lobby');
  const [inputValue, setInputValue] = useState('');
  const [isGridView, setIsGridView] = useState(false);
  const [activeGames, setActiveGames] = useState<{
    games: Game[];
    title: string;
    total?: number;
  } | null>(null);

  const {
    games = [],
    providers,
    handleSearchChange,
    handleSearchClear,
    loading
  } = useGameSearch({
    device: 'desktop'
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    if (e.target.value.length >= 2) {
      handleSearchChange(e);
    } else {
      handleSearchClear();
    }
  };

  const { searchNavMenuCategory } = useCategories(activeCategory);
  const { games: navMenuCategoryGames, category: navMenuCategory } = useGamesByCategory({
    slug: searchNavMenuCategory?.slug,
    device: 'desktop'
  });

  const onOuterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setActiveGames(null);
    handleSearchClear();
    setInputValue('');
    onClose();
  };

  const handleAllClick = (games: Game[], title: string, total?: number) => {
    setIsGridView(true);
    setActiveGames({ games, title, total });
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    if (!isOpen) {
      setActiveGames(null);
      handleSearchClear();
      setInputValue('');
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen, handleSearchClear]);

  if (!isOpen) return null;

  return (
    <>
      {createPortal(
        <div
          className="fixed inset-0 z-top4 bg-black/30 backdrop-blur-[10px]"
          onClick={onOuterClick}
        />,
        document.body
      )}
      {createPortal(
        <div className="fixed left-1/2 top-[50px] z-top5 -translate-x-1/2">
          <div className="rounded-lg w-[1000px]">
            <TextInput
              name="search"
              placeholder={t('search.placeholder')}
              value={inputValue}
              onChange={handleChange}
              leftIcon={<SearchIcon className="text-text-primary" />}
              autoFocus
              className="!mb-0 !min-h-0 w-full"
              inputClassName="!pl-7 !bg-background-card"
              autoComplete="off"
            />

            <div className="relative mt-1 overflow-hidden rounded-3 bg-background-body p-5 pr-1">
              <div className="custom-scrollbar max-h-[70vh] overflow-y-auto pr-3">
                {isGridView && activeGames ? (
                  <GridView
                    games={activeGames.games}
                    title={activeGames.title}
                    total={activeGames.games.length}
                    onBack={() => setIsGridView(false)}
                  />
                ) : (
                  <SearchResults
                    games={games || []}
                    providers={providers}
                    inputValue={inputValue}
                    navMenuCategoryGames={navMenuCategoryGames || []}
                    navMenuCategory={navMenuCategory}
                    onAllClick={handleAllClick}
                    loading={loading}
                  />
                )}
              </div>
              {games?.length > 0 && (
                <div className="absolute bottom-0 left-0 right-0 z-top5 h-[96px] bg-modal-fade" />
              )}
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  ) as ReactPortal;
};
