import { useAppConfig } from '@/entities/app-config';

export const useCategories = (selectedCategory?: string) => {
  const {
    config: { lobbyNavigationCategories, searchNavMenuCategory, success },
    loading
  } = useAppConfig();

  const categories = Array.isArray(lobbyNavigationCategories) ? lobbyNavigationCategories : [];

  const activeCategoryIndex = Math.max(
    categories.findIndex((category) => category.slug === selectedCategory),
    0
  );

  return {
    categories: lobbyNavigationCategories,
    activeCategoryIndex,
    searchNavMenuCategory,
    loading,
    success
  };
};
