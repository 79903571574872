import { useQuery } from '@apollo/client';
import { Device, gamesQuery, GamesResponse } from '@/entities/game';
import { getProvidersQuery, ProvidersResponse } from '@/entities/providers/api';
import { useCallback, useState, useRef, useEffect } from 'react';
import { Game } from '@/entities/game/types';
import { Provider } from '@/entities/providers/types';

const SEARCH_DEBOUNCE_DELAY = 300;
const MIN_SEARCH_LENGTH = 2;

type GamesSuccess = {
  success: true;
  items: Game[];
};

type ProvidersSuccess = {
  success: true;
  items: Provider[];
};

export function useGameSearch({ device }: { device: Device }) {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<{
    games: Game[];
    providers: Provider[];
  }>({ games: [], providers: [] });

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const isTypingRef = useRef<boolean>(false);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cancelPreviousRequest = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
    }
  }, []);

  const createNewAbortController = useCallback(() => {
    cancelPreviousRequest();
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current;
  }, [cancelPreviousRequest]);

  useEffect(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    if (searchValue.length >= MIN_SEARCH_LENGTH) {
      isTypingRef.current = true;

      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      typingTimeoutRef.current = setTimeout(() => {
        isTypingRef.current = false;
        createNewAbortController();
      }, SEARCH_DEBOUNCE_DELAY);

      debounceTimerRef.current = setTimeout(() => {
        if (!isTypingRef.current) {
          setDebouncedValue(searchValue);
        }
      }, SEARCH_DEBOUNCE_DELAY);
    } else {
      setDebouncedValue('');
    }

    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [searchValue, createNewAbortController]);

  const { loading: gamesLoading } = useQuery<GamesResponse>(gamesQuery, {
    variables: {
      input: {
        name: debouncedValue,
        devices: [device],
        limit: 0
      }
    },
    skip: !debouncedValue || debouncedValue.length < MIN_SEARCH_LENGTH,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const gamesData = data?.games as GamesSuccess;
      if (gamesData?.success) {
        setResults((prev) => ({
          ...prev,
          games: gamesData.items || []
        }));
      }
    },
    context: {
      fetchOptions: {
        signal: abortControllerRef.current?.signal
      }
    }
  });

  const { loading: providersLoading } = useQuery<ProvidersResponse>(getProvidersQuery, {
    variables: {
      input: {
        providerName: debouncedValue,
        limit: 0
      }
    },
    skip: !debouncedValue || debouncedValue.length < MIN_SEARCH_LENGTH,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const providersData = data?.providers as ProvidersSuccess;
      if (providersData?.success) {
        setResults((prev) => ({
          ...prev,
          providers: providersData.items || []
        }));
      }
    },
    context: {
      fetchOptions: {
        signal: abortControllerRef.current?.signal
      }
    }
  });

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      if (isTypingRef.current) {
        cancelPreviousRequest();
      }
      setSearchValue(value);
    },
    [cancelPreviousRequest]
  );

  const handleSearchClear = useCallback(() => {
    cancelPreviousRequest();
    setSearchValue('');
    setDebouncedValue('');
    setResults({ games: [], providers: [] });
  }, [cancelPreviousRequest]);

  useEffect(() => {
    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
    }

    setIsLoading(true);

    loadingTimeoutRef.current = setTimeout(() => {
      setIsLoading(gamesLoading || providersLoading);
    }, 1000);

    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, [gamesLoading, providersLoading, searchValue]);

  useEffect(() => {
    return () => {
      cancelPreviousRequest();
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, [cancelPreviousRequest]);

  const isSearchActive = debouncedValue.length >= MIN_SEARCH_LENGTH;

  return {
    search: searchValue,
    games: results.games,
    providers: results.providers,
    loading: isLoading,
    handleSearchChange,
    handleSearchClear,
    isSearchActive,
    resultsVisible: Boolean(searchValue) && !isTypingRef.current
  };
}
