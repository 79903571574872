import React from 'react';
import { GamesPane } from '@/entities/game/ui/games-pane';
import { useScopedI18n } from '@/shared/locales/client';
import { Game } from '@/entities/game/types';
import { PaneArrow } from '@/entities/game/ui/pane-arrow';
import ProvidersCarousel from '@/entities/providers/ui/providers-carousel';
import { getCategoryTitle } from '@/entities/category';
import { Provider } from '@/entities/providers/types';

type SearchResultsProps = {
  games: Game[];
  providers: Provider[];
  inputValue: string;
  navMenuCategoryGames: Game[];
  navMenuCategory: any;
  loading: boolean;
  onAllClick: (games: Game[], title: string, total?: number) => void;
};

export const SearchResults = ({
  games = [],
  providers,
  inputValue,
  navMenuCategoryGames,
  navMenuCategory,
  onAllClick,
  loading
}: SearchResultsProps) => {
  const t = useScopedI18n('lobby');

  const shouldShowResults = inputValue.length >= 2;
  const hasResults = providers?.length > 0 || games.length > 0;

  const renderSearchResults = () => {
    if (!shouldShowResults) {
      return null;
    }

    if (hasResults) {
      return (
        <>
          {providers?.length > 0 && (
            <div className="mb-4">
              <div className="mb-2 flex h-[44px] items-center justify-between">
                <h2 className="capitalize text-text-primary md:typography-headline-2-bold">
                  {t('search.result')}
                </h2>
                {providers.length > 3 && (
                  <div className="flex gap-1">
                    <PaneArrow
                      direction="right"
                      dataTestid="providers-navigation--prev"
                      className="provider-prev hidden md:!block"
                    />
                    <PaneArrow
                      direction="left"
                      dataTestid="providers-navigation--next"
                      className="provider-next hidden md:!block"
                    />
                  </div>
                )}
              </div>
              <ProvidersCarousel
                withShadow={false}
                items={providers}
                carouselClassName="!pr-0"
                cardClassName="md:!w-[218px] md:!min-w-[218px]"
              />
            </div>
          )}

          {games.length > 0 && (
            <GamesPane
              games={games}
              id="search-results"
              title={t('search.games')}
              total={games.length}
              className="mb-4"
              cardSize="compact"
              device="desktop"
              cardTitleClassName="typography-body-regular"
              cardProviderClassName="typography-caption"
              titleClassName="capitalize text-text-primary md:typography-headline-2-bold"
              onAllClick={() => onAllClick(games, t('search.result'), games.length)}
            />
          )}
        </>
      );
    }

    if (!loading) {
      return (
        <div className="mb:3 md:mb-4">
          <h2 className="mb-2 capitalize text-text-primary md:typography-headline-2-bold">
            {t('search.result')}
          </h2>
          <div className="typography-body-regular text-text-info">{t('search.notFound')}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {renderSearchResults()}
      {navMenuCategoryGames && navMenuCategory && (
        <GamesPane
          games={navMenuCategoryGames.slice(0, 20)}
          id="custom-category-games"
          title={getCategoryTitle(navMenuCategory)}
          total={navMenuCategoryGames.length}
          cardSize="compact"
          device="desktop"
          className="mb-3"
          titleClassName="capitalize text-text-primary md:typography-headline-2-bold"
          onAllClick={() =>
            onAllClick(
              navMenuCategoryGames,
              getCategoryTitle(navMenuCategory),
              navMenuCategoryGames.length
            )
          }
        />
      )}
    </>
  );
};
