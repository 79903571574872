import { gql } from '@apollo/client';
import { Provider } from '@/entities/providers/types';

export const getProvidersQuery = gql`
  query Providers($input: ProvidersInput) {
    providers(input: $input) {
      success
      error {
        message
      }
      items {
        id
        provider
        currency
        enabled
        bigLogo {
          url
          alt
        }
        smallLogo {
          url
          alt
        }
        gamesQuantity
        geoRestrictions
        source
        createdAt
        updatedAt
      }
      limit
      page
    }
  }
`;

export interface ProvidersSearchInput {
  name?: string;
  limit?: number;
  page?: number;
}

type Success = {
  success: true;
  items: Provider[];
  total: number;
  limit: number;
  page: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
};

type Error = {
  success: false;
  error: {
    message: string;
    stack?: string;
  };
};

export type ProvidersResponse = {
  providers: Success | Error;
};
