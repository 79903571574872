import React from 'react';
import { CaretLeftIcon, NewButton as Button } from '@/shared/ui';
import { GameCard } from '@/entities/game/ui/game-card';
import { useScopedI18n } from '@/shared/locales/client';
import classNames from 'classnames';
import { Game } from '@/entities/game/types';

type GridViewProps = {
  games: Game[];
  title: string;
  onBack: () => void;
  total: number;
};

export const GridView = ({ games, title, onBack, total }: GridViewProps) => {
  const t = useScopedI18n('lobby');

  return (
    <>
      <div className="mb-2 flex h-[44px] items-center justify-between pr-2">
        <button
          className="mr-2 mt-[1/2] inline border-none bg-none leading-[12px]"
          onClick={onBack}
          aria-label="Back"
          type="button"
        >
          <CaretLeftIcon className="text-text-primary" />
        </button>
        <h2 className="capitalize text-text-primary md:typography-headline-2-bold">{title}</h2>
        <Button
          variant="secondary"
          className={classNames(
            'ml-auto w-[181px] px-2 py-1',
            '!bg-button-brand-click !text-text-brand'
          )}
          onClick={onBack}
        >{`${t('all')} ${total}`}</Button>
      </div>
      <div className="mb-3 flex flex-wrap gap-2">
        {games.map((game, index) => (
          <GameCard
            key={index}
            data={game}
            size="compact"
            titleClassName="typography-body-regular"
            providerClassName="typography-caption"
          />
        ))}
      </div>
    </>
  );
};
